<template v-slot:body>
  <b-row>
    <b-col lg="12" sm="12">
      <b-overlay :show="loading">
      <b-row>
        <div class="col-md-12" id="form">
          <b-row>
            <b-col sm="3">
            </b-col>
            <b-col sm="6">
              <u><b><p style="font-size: 20px;text decoration:underline;" class="mb-2 text-center">{{ $t('elearning_tim.trainer_training_evaluation') }}</p></b></u>
            </b-col>
            <b-col sm="3">
            </b-col>
          </b-row>
      <b-overlay>
        <b-table-simple bordered>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('elearning_config.training_title') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ getTrainingTitle(circularData.training_title_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('elearning_config.organization') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ getOrganizationName(circularData.org_id) }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('globalTrans.date') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ date }}</b-th>
            </b-tr>
          </b-table-simple>
      </b-overlay>
          <div>
            <p>
              {{ $t('elearning_tim.trainer_evaluation_details_p1') }}<b>{{ $t('elearning_tim.trainer_evaluation_details_p2') }}</b>
            </p>
          </div>
          <div>
            <p  class="text-center py-4">
              <b>{{ $t('elearning_tim.trainer_evaluation_details_p3') }}</b>
            </p>
          </div>
          <div>
            <p  class="text-center">
              <b>{{ $t('elearning_tim.trainer_evaluation_details_p4') }}</b>
            </p>
          </div>
              <fieldset class="p-2 w-100">
                <b-overlay>
                  <div>
                    <p></p>
                    <b-table-simple bordered v-for="(item,index) in datas" :key="index">
                    <b-thead class="thead">
                      <b-tr >
                        <b-th class="text-center">{{ getTrainerName(item.trainer_id) }}, {{ getDesignationName(item.trainer_id) }}, {{ getOrgName(item.trainer_id) }}</b-th>
                        <b-th>{{$n(5)}}</b-th>
                        <b-th>{{$n(4)}}</b-th>
                        <b-th>{{$n(3)}}</b-th>
                        <b-th>{{$n(2)}}</b-th>
                        <b-th>{{$n(1)}}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tr v-for="(itm,index) in item.trainer_eva_marks" :key="index">
                      <b-td class="text-center">
                        {{ getTrainerEvaluation(itm.trainer_evaluation_detail_id) }}
                      </b-td>
                      <b-td>
                        <span v-if="itm.attain_marks === 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="itm.attain_marks === 4"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="itm.attain_marks === 3"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="itm.attain_marks === 2"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="itm.attain_marks === 1"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="6" class="text-right">{{ $t('globalTrans.total') }}: {{ $n(sumArray(item.trainer_eva_marks)) }}</b-td>
                    </b-tr>
                  </b-table-simple>
                  </div>
                </b-overlay>
              </fieldset>
            </div>
            <div>
                <b-card>
                  <div id="chart" class="px-3">
                      <h5 class="my-2 text-center pb-2">{{$t('elearning_tim.performance_of_resources_person')}}</h5>
                      <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                      <div class="tea-chart-number">
                          <p><i>{{ $t('elearning_tim.marks') }}</i></p>
                      </div>
                      <p class="tea-chart-year"><i>{{ $t('elearning_tim.trainer_name') }}</i></p>
                  </div>
              </b-card>
            </div>
          </b-row>
        </b-overlay>
    </b-col>
  </b-row>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['item'],
  created () {
    if (this.item) {
      this.getDetailsData()
      this.getTrainerDataList()
    }
  },
  mounted () {
  },
  data () {
    return {
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      ids: [],
      datas: [],
      date: [],
      circularData: [],
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      newSeriesData: [],
      categories: [],
      categoriesBn: []
    }
  },
  computed: {
    // loading () {
    //   return this.$store.state.commonObj.loading
    // },
    currentLocale: function () {
      return this.$i18n.locale
    },
    series () {
          return [{
              name: `${this.$t('elearning_tim.marks')}`,
              data: this.newSeriesData
          }
      ]
    },
    chartOptions () {
          return {
              chart: {
                  type: 'bar',
                  height: 200
              },
              plotOptions: {
                  bar: {
                      horizontal: false,
                      distributed: true,
                      columnWidth: '10%',
                      endingShape: 'rounded'
                  }
              },
              dataLabels: {
                  enabled: false
              },
              stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
              },
              xaxis: {
                  categories: this.currentLocale === 'en' ? this.categories : this.categoriesBn
              },
              yaxis: {
                  tickAmount: 6,
                  max: 30,
                  min: 0
              },
              fill: {
                  opacity: 1
              },
              colors: ['#77B6EA', '#B90E03', '#034092', '#F9EB17', '#172FF9', '#17F939', '#7D0EDA', '#755D58', '#2F2C61', '#9F4E40', '#9F3E40', '#304E40']
          }
      }
  },
  methods: {
    sumArray (arr) {
      return arr.reduce(function (previousVal, currentVal) {
        return previousVal + currentVal.attain_marks
        }, 0)
    },
    getTrainerEvaluation (id) {
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.find(doc => doc.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
    getTrainingTitle (id) {
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
    getOrganizationName (id) {
      const trainingTitleObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
  getTrainerDataList () {
    this.loader = true
    RestApi.getData(trainingElearningServiceBaseUrl, 'tim/trainer-evaluations/trainer-details').then(response => {
      this.loader = false
      if (response.success) {
        this.ids = response.data.map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
        })
      } else {
        this.ids = []
      }
    })
  },
  getTrainerName (id) {
    const dataStallCategory = this.ids.find(item => item.value === id)
    if (this.$i18n.locale === 'bn') {
      return dataStallCategory !== undefined ? dataStallCategory.text_bn : ''
    } else {
      return dataStallCategory !== undefined ? dataStallCategory.text_en : ''
    }
  },
  getDesignationName (id) {
    const dataStallCategory = this.ids.find(item => item.value === id)
    if (this.$i18n.locale === 'bn') {
      return dataStallCategory !== undefined ? dataStallCategory.designation_bn : ''
    } else {
      return dataStallCategory !== undefined ? dataStallCategory.designation_en : ''
    }
  },
  getOrgName (id) {
    const dataStallCategory = this.ids.find(item => item.value === id)
    if (this.$i18n.locale === 'bn') {
      return dataStallCategory !== undefined ? dataStallCategory.org_name_bn : ''
    } else {
      return dataStallCategory !== undefined ? dataStallCategory.org_name_en : ''
    }
  },
    async getDetailsData () {
      this.loading = true
      const params = {
        circularMemoNo: this.item.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tim/trainer-evaluations/details', params)
      if (!result.success) {
        this.datas = []
      } else {
        this.datas = result.data
        this.circularData = result.circularPublication
        const startDate = this.circularData.training_start_date.split('-')
        const endDate = this.circularData.training_end_date.split('-')
        let startMonthId = ''
        let endMonthId = ''
        startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
        endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
        if (startDate[1] === endDate[1]) {
          this.date = this.$n(startDate[2]) + (this.currentLocale === 'en' ? ' to ' : ' হতে ') + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        } else {
          this.date = this.$n(startDate[2]) + ' - ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + (this.currentLocale === 'en' ? ' to ' : ' হতে ') + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        }
        const trainerNameList = this.ids.map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
      })
          const chartInfo = result.chartInfo.map(item => {
            const name = trainerNameList.find(obj => obj.value === parseInt(item.trainer_id))
            if (name) {
                    const customItem = {
                    name_en: name.text_en,
                    name_bn: name.text_bn,
                    sorting_order: name.value
                }
                return Object.assign({}, item, customItem)
            } else {
                const customItem = {
                    name_en: '',
                    name_bn: '',
                    sorting_order: ''
                }
                return Object.assign({}, item, customItem)
            }
        }).sort((a, b) => a.sorting_order - b.sorting_order)
        const categoryArr = []
        const categoryBnArr = []
        const newSeriesArr = []
        chartInfo.forEach(item => {
            categoryArr.push(item.name_en)
            categoryBnArr.push(item.name_bn)
            newSeriesArr.push(item.sum)
        })
        this.categories = categoryArr
        this.categoriesBn = categoryBnArr
        this.newSeriesData = newSeriesArr
      }
      this.loading = false
    },
    async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.item.org_id, circularMemoNo: this.item.circular_memo_no, app_id: this.id })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, 'tim/trainer-evaluations/details', params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  /* custom chart design  */
.tea-chart-number {
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-15px, -50%);
}
.tea-chart-number p {
    transform: rotate(-90deg);
    font-weight: 600;
}
.font-bn .tea-chart-number {
    transform: translate(0, -50%);
}
.tea-chart-year {
    text-align: center;
    margin: -12px 0 10px;
    font-weight: 600;
}
</style>
