<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('elearning_tim.traineer_evaluation')}}</h4>
      </template>
    <template v-slot:searchBody>
      <b-row>
        <b-col lg="6" sm="12">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="circular_memo_no"
          >
            <template v-slot:label>
              {{ $t('elearning_iabm.circular_memo_no') }}
            </template>
            <b-form-input
                id="circular_memo_no"
                v-model="search.circular_memo_no"
                :placeholder="$t('elearning_iabm.circular_memo_no')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
          <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.organization')"
              label-for="organization"
          >
            <v-select name="org_id"
              v-model="search.org_id"
              label="text"
              :reduce="item => item.value"
              :options="orgList"
              :placeholder="$t('globalTrans.select')"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" sm="12">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_title_id"
          >
            <template v-slot:label>
              {{$t('elearning_config.training_title')}}
            </template>
            <v-select name="training_title_id"
              v-model="search.training_title_id"
              label="text"
              :reduce="item => item.value"
              :options="trainingTitleList"
              :placeholder="$t('globalTrans.select')"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" sm="12" class="text-right">
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
    </template>
  </card>
    <body-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{$t('elearning_tim.traineer_evaluation')}} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                        <b-form-checkbox
                          :id="'checkbox-' + field.label"
                          v-model="field.show"
                          :name="'checkbox-' + field.label"
                          value=1
                          unchecked-value=0
                        >
                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                  <b-form-select
                    id="per-page-select"
                    v-model="search.limit"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(training_title)="data">
                      <span class="capitalize">{{ data.item.training_title }}</span>
                    </template>
                    <!-- <template v-slot:cell(exam_date)="data">
                      <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(training_date)="data">
                        <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                      </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" v-b-modal.modal-4 class="btn_table_action table_action_view" title="view" @click="details(data.item)">
                        <i class="fas fa-eye"></i>
                      </a>
                    </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </body-card>
    <b-modal id="modal-4" size="lg" hide-footer :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title class="flex-grow-1">
        {{ $t('elearning_tim.traineer_evaluation') + ' ' + $t('globalTrans.details') }}
        <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <Details :id="detailsItemId" :item="itemData" :key="detailsItemId"  ref="details"/>
    </b-modal>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        sortBy: '',
        sortDirection: '',
        sortDesc: '',
        search: {
          circular_memo_no: '',
          org_id: [],
          training_title_id: [],
          limit: 30
        },
        detailsItemId: 0,
        itemData: [],
        courseList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '40%' } },
          // { labels: 'elearning_tim.trainer_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          // { labels: 'elearning_tim.trainee_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          // { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainingTitleList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year_bn' },
            { key: 'org_bn' },
            { key: 'training_title_bn' },
            { key: 'circular_memo_no' },
            // { key: 'trainer_name_bn' },
            // { key: 'trainee_name_bn' },
            // { key: 'training_date' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year' },
            { key: 'org' },
            { key: 'training_title' },
            { key: 'circular_memo_no' },
            // { key: 'trainer_name' },
            // { key: 'trainee_name' },
            // { key: 'training_date' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    watch: {
      'search.training_title_id': function (newValue) {
        this.courseList = this.getCourseList(newValue)
      }
    },
    async created () {
      /* this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
      }) */
      this.loadData()
    },
    mounted () {
      flatpickr('.fromDate', {})
    },
    methods: {
      details (item) {
        this.detailsItemId = item.id
        this.itemData = item
      },
      getCourseList (trainingTitleId) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (trainingTitleId) {
          return courseList.filter(item => item.training_title_id === trainingTitleId)
        }
        return courseList
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          // const trainerObj = this.$store.state.TrainingElearning.commonObj.personalInfoList.find(doc => doc.value === parseInt(item.trainer_id))
          // const trainerData = {}
          // if (typeof trainerObj !== 'undefined') {
          //   trainerData.trainer_name = trainerObj.text_en
          //   trainerData.trainer_name_bn = trainerObj.text_bn
          // } else {
          //   trainerData.trainer_name = ''
          //   trainerData.trainer_name_bn = ''
          // }
          // const traineeObj = this.$store.state.TrainingElearning.commonObj.personalInfoList.find(doc => doc.value === parseInt(item.trainee_id))
          // const customItems = {}
          // if (typeof traineeObj !== 'undefined') {
          //   customItems.trainee_name = traineeObj.text_en
          //   customItems.trainee_name_bn = traineeObj.text_bn
          // } else {
          //   customItems.trainee_name = ''
          //   customItems.trainee_name_bn = ''
          // }
          item.trainer_evaluation_marks = item.trainer_evaluation_marks.map(trainerEvaluationMark => {
            const trainerEvaluation = this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.find(trainerEvaluationObj => trainerEvaluationObj.value === parseInt(trainerEvaluationMark.trainer_evaluation_detail_id))
            const customItem = {
              evaluation_name: trainerEvaluation?.text_en,
              evaluation_name_bn: trainerEvaluation?.text_bn
            }
            return Object.assign({}, trainerEvaluationMark, customItem)
          })
          return Object.assign({}, { serial: index }, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
